import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Box from '../components/Box';

import { fetchMatrixs } from '../actions/matrixs';
import { fetchCustomerPDA } from '../actions/customers';
import { fetchRentals } from '../actions/rentals';
import { fetchMachines } from '../actions/machines';
import OcupationCustomerFilterForm from '../forms/OcupationCustomerFilterForm';
import { Bar } from 'react-chartjs-2';

class OcupationCustomerDailyView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      rentalsData: null, // daily aggregated rentals data (for charting)
    };

    this.progressBar = this.progressBar.bind(this);
    this.processRentalsData = this.processRentalsData.bind(this);
  }

  componentDidMount() {
    const { type, id, role } = this.props;

    const startDate = new Date();
    const endDate = new Date()

    // Chain the fetches. (Note: We still fetch matrixs and machines
    // in order to obtain total available lockers and zone information.)
    if (type === 'PDA') {
      this.props
        .fetchCustomerPDA(id)
        .then((response) => {
          this.props
            .fetchMatrixs('', '', response.response.Customer, '')
            .then((response) => {
              this.props.fetchRentals('','',startDate,endDate).then((response) => {
                this.props.fetchMachines().then((response) => {
                  this.processRentalsData();
                  this.setState({ loading: false });
                });
              });
            });
        })
        .catch((error) => {
          console.error('Error fetching customer PDA:', error);
          this.setState({ error: 'Failed to fetch customer PDA', loading: false });
        });
    } else if (this.props.role === 'CUSTOMER') {
      this.props
        .fetchMatrixs('', '', id, '')
        .then((response) => {
          this.props.fetchRentals('','',startDate,endDate).then((response) => {
            this.props.fetchMachines().then((response) => {
              this.processRentalsData();
              this.setState({ loading: false });
            });
          });
        });
    } else {
      this.props
        .fetchMatrixs('', '', '', '')
        .then((response) => {
          this.props.fetchRentals('','',startDate,endDate).then((response) => {
            this.props.fetchMachines().then((response) => {
              this.processRentalsData();
              this.setState({ loading: false });
            });
          });
        });
    }
  }

  getLockerName(type) {
    switch (type) {
      case "BNS":
        return "Básica";
      case "CNS":
        return "Básica Carga";
      case "BWS":
        return "Básica XL";
      case "BNM":
        return "Grande";
      case "CNM":
        return "Grande Carga";
      case "BWM":
        return "Grande XL";
      case "CWM":
        return "Grande Carga XL";
      case "BNL":
        return "Jumbo";
      case "CNL":
        return "Jumbo Carga";
      case "BWL":
        return "Jumbo XL";
      case "CWL":
        return "Jumbo Carga XL";
      case "BNXL":
        return "Jumbo";
      case "CNXL":
        return "Jumbo Carga";
      case "BWXL":
        return "Jumbo XL";
      case "CWXL":
        return "Jumbo Carga XL";
      case "BN2XL":
        return "Jumbo";
      case "CN2XL":
        return "Jumbo Carga";
      case "BW2XL":
        return "Jumbo XL";
      case "CW2XL":
        return "Jumbo Carga XL";
      case "BN3XL":
        return "2 Equipos de esquí";
      case "CN3XL":
        return "3 Equipos de esquí";
      case "BW3XL":
        return "4 Equipos de esquí";
      default:
        return "None";
    }
  }

  /***********************
   * UTILITY FUNCTIONS
   ***********************/
  progressBar(actual, objective) {
    const percentage = Math.min((actual / objective) * 100, 100);
    let color = "bg-warning";
    if (percentage < 50) {
      color = "bg-success";
    } else if (percentage > 80) {
      color = "bg-danger";
    }
    return (
      <div className="progress-container">
        <div className={`progress-bar ${color}`} style={{ width: `${percentage}%` }}></div>
        <div className="progress-percentage">{percentage.toFixed(2)}%</div>
      </div>
    );
  }

  getZoneName(zone) {
    switch (Number(zone)) {
      case 0:
        return 'GENERAL';
      case 1:
        return 'BAQUEIRA';
      case 2:
        return 'BERET';
      case 3:
        return 'BONAIGUA';
      case 4:
        return 'RUDA';
      case 5:
        return 'BOSQUE';
      default:
        return 'NONE';
    }
  }

  /****************************************************
   * RENTALS-BASED CALCULATIONS (REPLACES MATRIX DATA)
   ****************************************************/

  /**
   * processRentalsData():
   * – Uses the filter period (from the form) and rental data to build a daily
   *   aggregation. For each day in the filter period, we sum (across all valid rentals)
   *   the number of lockers rented (i.e. the length of LockerMatricula) for each day
   *   that the rental overlaps.
   */
  processRentalsData() {
    const { rentals, matrixs, filterStartDate, filterEndDate } = this.props;
    if (!rentals || !matrixs) return;

    // Use totalLockers from your matrix data (assumed static)
    const totalLockers = matrixs.reduce((sum, matrix) => {
      if (matrix.Locker && Array.isArray(matrix.Locker)) {
        return sum + matrix.Locker.length;
      }
      return sum;
    }, 0);

    // Use the filter period from the form if available, else use rental range
    let filterStart, filterEnd;
    if (filterStartDate && filterEndDate) {
      filterStart = moment(filterStartDate);
      filterEnd = moment(filterEndDate);
    } else {
      const dates = [];
      rentals.forEach(rental => {
        dates.push(moment(rental.StartDate));
        dates.push(moment(rental.FinishDate));
      });
      filterStart = moment.min(dates);
      filterEnd = moment.max(dates);
    }

    // Build a dictionary of daily rented locker counts.
    const rentalsByDate = {};
    // Exclude rentals that are canceled, PRERENT, or of type COUNTERINVOICE
    const validRentals = rentals.filter(rental =>
      rental.Status !== 'CANCELED' &&
      rental.Status !== 'PRERENT' &&
      rental.Type !== 'COUNTERINVOICE'
    );
    validRentals.forEach((rental) => {
      if (rental.LockerMatricula && rental.LockerMatricula.length > 0) {
        const rentalStart = moment(rental.StartDate);
        const rentalEnd = moment(rental.FinishDate);
        // Determine the effective overlap with the filter period
        const effectiveStart = moment.max(rentalStart, filterStart);
        const effectiveEnd = moment.min(rentalEnd, filterEnd);
        if (effectiveEnd.isSameOrAfter(effectiveStart)) {
          const days = effectiveEnd.diff(effectiveStart, 'days') + 1;
          for (let i = 0; i < days; i++) {
            const currentDate = effectiveStart.clone().add(i, 'days').format('YYYY-MM-DD');
            rentalsByDate[currentDate] = (rentalsByDate[currentDate] || 0) + rental.LockerMatricula.length;
          }
        }
      }
    });

    // Build an array of daily data points from filterStart to filterEnd.
    const dailyData = [];
    for (let date = filterStart.clone(); date.isSameOrBefore(filterEnd); date.add(1, 'days')) {
      const currentDate = date.format('YYYY-MM-DD');
      dailyData.push({
        date: currentDate,
        rented: rentalsByDate[currentDate] || 0,
        total: totalLockers,
      });
    }
    this.setState({ rentalsData: dailyData });
  }

  /**
   * calculateOccupation():
   * – Computes overall occupancy over the filter period.
   * – For each day in the period we sum the rented lockers (from rentals data)
   *   then average over the period.
   */
  calculateOccupation() {
    const { rentals, filterStartDate, filterEndDate, matrixs } = this.props;
    if (!rentals || !filterStartDate || !filterEndDate || !matrixs) {
      return { totalLockers: 0, occupiedLockers: 0, occupationPercentage: 0 };
    }

    // Total available lockers from matrixs (static data)
    const totalLockers = matrixs.reduce((sum, matrix) => {
      if (matrix.Locker && Array.isArray(matrix.Locker)) {
        return sum + matrix.Locker.length;
      }
      return sum;
    }, 0);

    const filterStart = moment(filterStartDate);
    const filterEnd = moment(filterEndDate);
    const numDays = filterEnd.diff(filterStart, 'days') + 1;
    let dailyOccupied = {};
    for (let i = 0; i < numDays; i++) {
      let day = filterStart.clone().add(i, 'days').format('YYYY-MM-DD');
      dailyOccupied[day] = 0;
    }

    const validRentals = rentals.filter(rental =>
      rental.Status !== 'CANCELED' &&
      rental.Status !== 'PRERENT' &&
      rental.Type !== 'COUNTERINVOICE'
    );
    validRentals.forEach(rental => {
      if (rental.LockerMatricula && rental.LockerMatricula.length > 0) {
        const rentalStart = moment(rental.StartDate);
        const rentalEnd = moment(rental.FinishDate);
        const effectiveStart = moment.max(rentalStart, filterStart);
        const effectiveEnd = moment.min(rentalEnd, filterEnd);
        if (effectiveEnd.isSameOrAfter(effectiveStart)) {
          const daysOverlap = effectiveEnd.diff(effectiveStart, 'days') + 1;
          for (let i = 0; i < daysOverlap; i++) {
            let day = effectiveStart.clone().add(i, 'days').format('YYYY-MM-DD');
            dailyOccupied[day] += rental.LockerMatricula.length;
          }
        }
      }
    });
    let totalOccupiedDays = 0;
    Object.values(dailyOccupied).forEach(count => {
      totalOccupiedDays += count;
    });
    const averageOccupiedLockers = totalOccupiedDays / numDays;
    const occupationPercentage = totalLockers > 0 ? ((averageOccupiedLockers / totalLockers) * 100).toFixed(2) : 0;
    return {
      totalLockers,
      occupiedLockers: averageOccupiedLockers.toFixed(2),
      occupationPercentage,
    };
  }

  /**
   * renderZonesTable():
   * – Groups rental data by zone. For each rental that overlaps the filter period,
   *   we add its LockerMatricula count to the appropriate zone.
   * – For totals per zone, we still use the matrixs data (via machines) to get the total lockers.
   */
  renderZonesTable() {
    const { rentals, machines, matrixs, filterStartDate, filterEndDate, category } = this.props;
    if (!machines || !rentals || !matrixs || !filterStartDate || !filterEndDate) return null;

    // Build a lookup for total lockers per zone using matrixs and machines.
    const machineMap = machines.reduce((acc, machine) => {
      acc[machine.Serial] = machine;
      return acc;
    }, {});
    let zoneTotals = {};
    matrixs.forEach(matrix => {
      const machine = machineMap[matrix.Serial];
      if (machine && machine.Contract) {
        const zone = machine.Contract.Zone;
        if (!zoneTotals[zone]) {
          zoneTotals[zone] = 0;
        }
        if (matrix.Locker && Array.isArray(matrix.Locker)) {
          zoneTotals[zone] += matrix.Locker.length;
        }
      }
    });

    // Initialize an object to hold daily rented counts per zone.
    const filterStart = moment(filterStartDate);
    const filterEnd = moment(filterEndDate);
    const numDays = filterEnd.diff(filterStart, 'days') + 1;
    let zoneRentedDaily = {}; // { zone: { day: count, ... }, ... }
    Object.keys(zoneTotals).forEach(zone => {
      zoneRentedDaily[zone] = {};
      for (let i = 0; i < numDays; i++) {
        const day = filterStart.clone().add(i, 'days').format('YYYY-MM-DD');
        zoneRentedDaily[zone][day] = 0;
      }
    });

    const validRentals = rentals.filter(rental =>
      rental.Status !== 'CANCELED' &&
      rental.Status !== 'PRERENT' &&
      rental.Type !== 'COUNTERINVOICE'
    );
    validRentals.forEach(rental => {
      if (
        rental.LockerMatricula &&
        rental.LockerMatricula.length > 0 &&
        rental.Machine &&
        rental.Machine.Contract
      ) {
        const zone = rental.Machine.Contract.Zone;
        if (zoneTotals[zone] !== undefined) {
          const rentalStart = moment(rental.StartDate);
          const rentalEnd = moment(rental.FinishDate);
          const effectiveStart = moment.max(rentalStart, filterStart);
          const effectiveEnd = moment.min(rentalEnd, filterEnd);
          if (effectiveEnd.isSameOrAfter(effectiveStart)) {
            const daysOverlap = effectiveEnd.diff(effectiveStart, 'days') + 1;
            for (let i = 0; i < daysOverlap; i++) {
              const day = effectiveStart.clone().add(i, 'days').format('YYYY-MM-DD');
              zoneRentedDaily[zone][day] += rental.LockerMatricula.length;
            }
          }
        }
      }
    });

    // For each zone, compute the average daily rented lockers.
    const rows = Object.keys(zoneTotals).map(zone => {
      const dailyCounts = Object.values(zoneRentedDaily[zone]);
      const totalRented = dailyCounts.reduce((sum, count) => sum + count, 0);
      const averageRented = totalRented / numDays;
      const percentage = ((averageRented / zoneTotals[zone]) * 100).toFixed(2);
      return (
        <tr key={zone}>
          <td>{category === 'BAQUEIRA' ? this.getZoneName(zone) : zone}</td>
          <td>{totalRented}</td>
          <td>{zoneTotals[zone]}</td>
          <td>{this.progressBar(totalRented, zoneTotals[zone])}</td>
        </tr>
      );
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Zona</th>
              <th scope="col">Alquiladas</th>
              <th scope="col">Totales</th>
              <th scope="col">Porcentaje</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }

  /**
   * renderTable():
   * – As an example, here we group by the rental’s “Type” (e.g. RENTAL, SEASON, etc.)
   *   and compute the total number of lockers rented for each.
   * (If you need a breakdown by physical locker type you may have to join rental data with
   *  additional locker metadata.)
   */
  renderTable() {
    const { rentals, matrixs } = this.props;
    if (!rentals || !matrixs) return null;
  
    // Build total available locker counts by type from the matrix data.
    const totalLockerCounts = {};
    matrixs.forEach(matrix => {
      if (matrix.Locker && Array.isArray(matrix.Locker)) {
        matrix.Locker.forEach(locker => {
          // Build a type key from the locker properties.
          const lockerType = `${locker.Mode}${locker.Wide}${locker.Type}`;
          totalLockerCounts[lockerType] = (totalLockerCounts[lockerType] || 0) + 1;
        });
      }
    });
  
    // Build rented counts by locker type using the rental data.
    // Only count rentals that are not canceled, not PRERENT, and not COUNTERINVOICE.
    const rentedCounts = {};
    const validRentals = rentals.filter(rental =>
      rental.Status !== 'CANCELED' &&
      rental.Status !== 'PRERENT' &&
      rental.Type.trim().toUpperCase() !== 'COUNTERINVOICE'
    );
  
    validRentals.forEach(rental => {
      // Find the corresponding matrix for this rental using the machine serial.
      if (rental.Machine && rental.Machine.Serial) {
        const matrix = matrixs.find(m => m.Serial === rental.Machine.Serial);
        if (matrix && matrix.Locker && Array.isArray(matrix.Locker)) {
          // For each locker index (as a string) in LockerMatricula...
          if (rental.LockerMatricula && Array.isArray(rental.LockerMatricula)) {
            rental.LockerMatricula.forEach(indexStr => {
              // Convert the string index to a number.
              // (If your indices are 1-based, use: const lockerIndex = parseInt(indexStr, 10) - 1;)
              const lockerIndex = parseInt(indexStr, 10);
              const locker = matrix.Locker[lockerIndex];
              if (locker) {
                const lockerType = `${locker.Mode}${locker.Wide}${locker.Type}`;
                rentedCounts[lockerType] = (rentedCounts[lockerType] || 0) + 1;
              }
            });
          }
        }
      }
    });
  
    // Define a reference order for sorting locker types.
    const lockerOrder = [
      "BNS", "CNS", "BWS",
      "BNM", "CNM", "BWM", "CWM",
      "BNL", "CNL", "BWL", "CWL",
      "BNXL", "CNXL", "BWXL", "CWXL",
      "BN2XL", "CN2XL", "BW2XL", "CW2XL",
      "BN3XL", "CN3XL", "BW3XL"
    ];
  
    // Build the rows for the table.
    const rows = Object.entries(totalLockerCounts)
      .map(([lockerType, totalCount]) => {
        const rentedCount = rentedCounts[lockerType] || 0;
        return {
          type: lockerType,
          lockerName: this.getLockerName(lockerType),
          rentedCount,
          totalCount,
          percentage: totalCount > 0 ? ((rentedCount / totalCount) * 100).toFixed(2) : "0.00"
        };
      })
      .sort((a, b) => lockerOrder.indexOf(a.type) - lockerOrder.indexOf(b.type))
      .map(({ type, lockerName, rentedCount, totalCount, percentage }) => (
        <tr key={type}>
          <td>{lockerName}</td>
          <td>{rentedCount}</td>
          <td>{totalCount}</td>
          <td>{this.progressBar(rentedCount, totalCount)}</td>
        </tr>
      ));
  
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Tipo de taquilla</th>
              <th scope="col">Alquiladas</th>
              <th scope="col">Totales</th>
              <th scope="col">Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
  
  /***********************
   * RENDER METHOD
   ***********************/
  render() {
    const { matrixs, rentals, machines, category } = this.props;
    const { loading, error } = this.state;

    if (loading || !matrixs || !rentals || !machines) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Loading..." />
        </div>
      );
    }
    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    // Use the rentals-based overall occupancy calculation.
    const { totalLockers, occupiedLockers, occupationPercentage } = this.calculateOccupation();

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OCUPACIÓN </span>
              <span className="text-vw-light">ACUMULADA</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <OcupationCustomerFilterForm />
        </Box>
        <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
          <div className="row" style={{ textAlign: 'center', margin: 'auto', width: 'fit-content' }}>
            <h5 className="text-vw-light" style={{ marginRight: '2vw' }}>OCUPACIÓN:</h5>
            <h5 style={{ marginRight: '2vw' }}>{occupationPercentage} %</h5>
            <h5 className="text-vw-light" style={{ marginRight: '2vw' }}>ALQUILADAS:</h5>
            <h5 style={{ marginRight: '2vw' }}>{occupiedLockers}</h5>
            <h5 className="text-vw-light" style={{ marginRight: '2vw' }}>TOTAL:</h5>
            <h5 style={{ marginRight: '2vw' }}>{totalLockers}</h5>
          </div>
        </div>

        <div className="row mb-2 mt-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OCUPACIÓN </span>
              <span className="text-vw-light">POR ZONA</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.renderZonesTable()}

        <div className="row mb-2 mt-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OCUPACIÓN </span>
              <span className="text-vw-light">POR TIPO DE TAQUILLA</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.renderTable()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  matrixs: state.matrixs.matrixs,
  loading: state.matrixs.loading,
  error: state.matrixs.error,
  role: state.auth.role,
  id: state.auth.id,
  type: state.auth.type,
  category: state.auth.category,
  rentals: state.rentals.rentals,
  machines: state.machines.machines,
  // Retrieve the filter period values from the Redux Form (if available)
  filterStartDate: state.form.OcupationCustomerFilterForm?.values?.StartDate,
  filterEndDate: state.form.OcupationCustomerFilterForm?.values?.EndDate,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchCustomerPDA: bindActionCreators(fetchCustomerPDA, dispatch),
  fetchRentals: bindActionCreators(fetchRentals, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OcupationCustomerDailyView);
