import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import moment from 'moment';

import { fetchRentals } from '../actions/rentals';
import { fetchMachines } from '../actions/machines';
import { fetchCustomers } from '../actions/customers';
import { fetchMatrixs } from '../actions/matrixs';

import { renderInputField, renderSelectField } from '../utils/forms';

const validateDateRange = (values) => {
  const { StartDate, EndDate } = values;

  if (StartDate && EndDate) {
    const startDate = moment(StartDate);
    const endDate = moment(EndDate);

    if (endDate.diff(startDate, 'months') > 12) {
      return {
        EndDate: 'El rango máximo de consulta es de un año.',
      };
    }
  }

  return {};
};

class OcupationCustomerFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { type, role } = this.props;

    this.load(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps);
  }

  load(props) {
    const { role, machines, rentals } = props;

    if (!rentals.loading) {
      //this.submit(this.props.formValues);
    }

    if (!this.state.loaded && machines && (role !== 'ADMIN')) {
      this.setState({ loaded: true });
    }
  }

  submit(props) {

    // Start the timer when fetching sales data begins
    const startTime = performance.now();

    return this.props.fetchRentals(
      props.Machine,
      '',
      props.StartDate,
      props.EndDate,
    ).then((response) => {

      this.props.fetchMatrixs(props.Machine, '', '', '')
      // Calculate the total time taken to fetch sales data
      const endTime = performance.now();
      const totalTimeInSeconds = (endTime - startTime) / 1000;

      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields,
        });
      }

      return response;
    });
  }

  render() {
    const {
      error,
      submitting,
      handleSubmit,
      machines,
      role,
      type
    } = this.props;

    if (!machines) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        {submitting ? (<div className="row"></div>) : (
          <div className="row">
            <div className="col">
              <Field
                name="Machine"
                component={renderSelectField}
                label="Máquina"
              >
                <option value="">Todas</option>
                {machines.map((machine) => (
                  <option value={machine._id} key={machine._id}>
                    {machine.Serial} - {machine.Customer?.Fullname || 'None'}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col">
              <Field
                name="StartDate"
                component={renderInputField}
                type="date"
                label="Fecha inicio"
              />
            </div>
            <div className="col">
              <Field
                name="EndDate"
                component={renderInputField}
                type="date"
                label="Fecha fin"
              />
              {error && <div className="text-danger">{error.EndDate}</div>}
            </div>
          </div>
        )}
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row justify-content-center mt-2">
          {submitting ? (<div class="loading-wheel"></div>) : (
            <button className="btn bg-vw-dark text-white mr-2" type="submit" disabled={submitting}>
              <i className="fas fa-sync mr-1"></i>Actualizar
            </button>
          )}
        </div>
      </form>
    );
  }
}

OcupationCustomerFilterForm = reduxForm({
  form: 'OcupationCustomerFilterForm',
  validate: validateDateRange,
})(OcupationCustomerFilterForm);

const selector = formValueSelector('OcupationCustomerFilterForm');

const mapStateToProps = (state) => ({
  machines: state.machines.machines,
  matrixs: state.matrixs.matrixs,
  rentals: state.rentals.rentals,
  role: state.auth.role,
  type: state.auth.type,
  initialValues: {
    Machine: '',
    StartDate: new Date().toISOString().substring(0, 10),
    EndDate: new Date().toISOString().substring(0, 10),
  },
  formValues: {
    Machine: selector(state, 'Machine'),
    StartDate: selector(state, 'StartDate'),
    EndDate: selector(state, 'EndDate'),
  },
});

const mapDispatchToProps = (dispatch) => ({
  fetchRentals: bindActionCreators(fetchRentals, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OcupationCustomerFilterForm);