import React from 'react';
import { history } from '../store';

class MatrixList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixLockCounts: {},      // Per-machine locker counts by version
      matrixTotalLockCounts: {}  // Global totals for each version + overall total
    };
  }

  componentDidMount() {
    this.calculateMatrixLockCounts();
  }

  calculateMatrixLockCounts() {
    // Initialize objects; note that we no longer predefine version keys.
    const matrixLockCounts = {};
    const matrixTotalLockCounts = { total: 0 };

    // Loop through each matrix
    this.props.matrixs.forEach(matrix => {
      const versionCounts = {};

      // Loop through each Locker in the matrix
      matrix.Locker.forEach(lock => {
        const version = lock.KerongVersion;
        // Update the count for the version in the current matrix.
        versionCounts[version] = (versionCounts[version] || 0) + 1;
        // Update the global count for the version.
        matrixTotalLockCounts[version] = (matrixTotalLockCounts[version] || 0) + 1;
        // Increase the overall total count.
        matrixTotalLockCounts.total++;
      });

      // Store the counts for this machine using its Serial as key.
      matrixLockCounts[matrix.Serial] = versionCounts;
    });

    // Update the state.
    this.setState({ matrixLockCounts, matrixTotalLockCounts });
  }

  goTo(Serial, e) {
    history.push(`/machines/${Serial}`);
  }

  render() {
    const { matrixLockCounts, matrixTotalLockCounts } = this.state;
    
    // Build a sorted list of versions based on the keys in matrixTotalLockCounts,
    // excluding the overall "total" key.
    const versions = matrixTotalLockCounts
      ? Object.keys(matrixTotalLockCounts)
          .filter(key => key !== 'total')
          .sort((a, b) => Number(a) - Number(b))
      : [];

    return (
      <div className="table-responsive">
        {/* General Table: Totals per version */}
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Total Escaneados</th>
              {versions.map(version => (
                <th scope="col" key={version}>
                  Total Versión {version}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["total"] : 0}</td>
              {versions.map(version => (
                <td key={version}>
                  {matrixTotalLockCounts ? matrixTotalLockCounts[version] || 0 : 0}
                </td>
              ))}
            </tr>
          </tbody>
        </table>

        {/* Table per machine */}
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Máquina</th>
              {versions.map(version => (
                <th scope="col" key={version}>
                  Versión {version}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.matrixs.map(matrix => (
              <tr key={matrix.Serial} onClick={e => this.goTo(matrix.Serial, e)}>
                <td>{matrix.Serial}</td>
                {versions.map(version => (
                  <td key={version}>
                    {matrixLockCounts[matrix.Serial]
                      ? matrixLockCounts[matrix.Serial][version] || 0
                      : 0}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default MatrixList;
