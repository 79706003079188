import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchRemoteActions, addRemoteAction } from '../actions/remoteactions'
import { renderInputField, renderSelectField } from '../utils/forms'

class RemoteActionAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  submit(props) {
    // insert machine id to the query
    props.machine = this.props.machine._id
    props.executor = this.props.fullname

    return this.props.addRemoteAction(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        // fetch remote actions so that they are refreshed
        this.props.fetchRemoteActions(this.props.machine.Serial)

        return response
      })
  }

  render() {
    const { error, pristine, submitting, handleSubmit, machine,
      fetchRemoteActions } = this.props

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-4 mt-5">
            <div className="flex justify-content-center">Acciones Operativas</div>
            <div className="row">
              <button className="btn btn-remoteAction bg-vw-dark text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'REBOOT'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-power-off mr-1"></i>REINICIAR
              </button>
              <button className="btn btn-remoteAction bg-vw-dark text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'DOOR_OPEN'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-door-open mr-1"></i>ABRIR KIOSCO
              </button>
              <button className="btn btn-remoteAction bg-vw-dark text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'GET_ESP32'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-bluetooth mr-1"></i>ESTADO ESP32
              </button>
              <button className="btn btn-remoteAction bg-vw-dark text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'GET_MAC'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-globe mr-1"></i>SOLICITAR MAC
              </button>
            </div>
          </div>
          <div className="col-xs-12 col-md-4 mt-5">
            <div className="flex justify-content-center">Acciones Críticas</div>
            <div className="row">
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'ANYDESK'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-laptop mr-1"></i>ANYDESK
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'UPLOAD_MATRIX'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-keyboard mr-1"></i>SOLICITAR MATRIZ
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'DOWNLOAD_MATRIX'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-keyboard mr-1"></i>ENVIAR MATRIZ
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'SEND_LASTSALE'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-euro-sign mr-1"></i>ENVIAR LASTSALE
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'UNRENT_ALL'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-lock-open mr-1"></i>DESALQUILAR TODAS
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'FORCEDNIGHTSCAN'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-wifi mr-1"></i>FORCED NIGHTSCAN
              </button>
              <button className="btn btn-remoteAction bg-vw-red text-white" onClick={() => {
                this.props.dispatch(change('RemoteActionAddForm', 'code', 'OPEN_ALL'));
                this.props.dispatch(change('RemoteActionAddForm', 'arguments', 'vacway'));
              }}>
                <i className="fas fa-door-open mr-1"></i>ABRIR TODAS
              </button>
            </div>
          </div>
          <div className="col-xs-12 col-md-4 mt-5">
            <div className="flex justify-content-center">Formulario</div>
            <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3">
              <div className="align-items-center justify-content-center">
                  <Field name="code" component={renderSelectField}>
                    <option value="">Selecciona una acción...</option>
                    <option value="COMMAND">Ejecutar comando</option>
                    <option value="REBOOT">Reiniciar máquina</option>
                    <option value="DOOR_OPEN">Abrir puerta</option>
                    <option value="UPDATE">Actualizar APP con UPDATER</option>
                    <option value="APP_UPDATE">Actualizar APP con APP</option>
                    <option value="UPDATER">Actualizar UPDATER</option>
                    <option value="FIRMWARE">Actualizar FIRMWARE</option>
                    <option value="KERONG_OTA">Actualizar KERONG</option>
                    <option value="BOOTLOADER_OTA">Actualizar BOOTLOADER</option>
                    <option value="KERONG_PROGRAM_DOWNLOAD">KERONG Descargar Programa</option>
                    <option value="KERONG_BOOTLOADER_DOWNLOAD">KERONG Descargar Bootloader</option>
                    <option value="ESP32_OTA">Actualizar ESPRESSIF</option>
                    <option value="FORCEDNIGHTSCAN">Iniciar Forced Nightscan</option>
                    <option value="ANYDESK">Iniciar Anydesk</option>
                    <option value="UPLOAD_MATRIX">Solicitar matriz</option>
                    <option value="DOWNLOAD_MATRIX">Enviar matriz</option>
                    <option value="GET_ESP32">Estado espressifs</option>
                    <option value="GET_MAC">Solicitar MAC</option>
                    <option value="SEND_LASTSALE">Enviar código de venta</option>
                    <option value="UNRENT_ALL">Desalquilar todas</option>
                    <option value="BLE_OFF">Apagar Bluetooth</option>
                    <option value="SINGLE_NIGHTSCAN">Vincular Individual</option>
                    <option value="COMMONS_UPDATE">Actualizar Audios/Videos</option>
                    <option value="OPEN_ALL">Abrir todas las taquillas</option>
                    <option value="DEBUG_MODE">Cambiar a modo Desarrollo</option>
                  </Field>
                  <Field name="arguments"
                    type="text"
                    component={renderInputField}
                    placeholder="Argumentos" />
              </div>
              <div className="form-row align-items-center flex justify-content-center">
                <div className="col-md-12 align-items-center flex justify-content-center">
                  <button className="btn bg-vw-light text-white mr-1" type="submit"
                    disabled={pristine || submitting}>
                    <i className="fas fa-bolt mr-1"></i>Ejecutar
                  </button>
                  <button className="btn btn-info"
                    type="button"
                    onClick={() => fetchRemoteActions(machine.Serial)}>
                    <i className="fas fa-sync mr-1"></i>Actualizar
                  </button>
                </div>
              </div>
              <div className="form-row">
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

RemoteActionAddForm = reduxForm({
  form: 'RemoteActionAddForm',
})(RemoteActionAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  addRemoteAction: bindActionCreators(addRemoteAction, dispatch),
  fetchRemoteActions: bindActionCreators(fetchRemoteActions, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionAddForm)

