import React from 'react'
import { history } from '../store'

class ModuleList extends React.Component {
  goTo(Code, e) {
    history.push(`/modules/${Code}`)
  }

  render() {
    const { prefix, modules } = this.props

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Módulos Taquillas {prefix}</th>
              <th scope="col">Modo</th>
              <th scope="col">Ancho</th>
              <th scope="col">Espressif</th>
              <th scope="col">Género</th>
              <th scope="col">Cierre</th>
            </tr>
          </thead>
          <tbody>
            {modules.map(module => (

              (module.Code).substring(0, 5) == prefix ? (

                <tr key={module.Code}
                  onClick={e => this.goTo(module.Code, e)}>
                  <th scope="row">{module.Code}</th>
                  <th scope="row">{module.Mode || 'None'}</th>
                  <th scope="row">{module.Wide || 'None'}</th>
                  <th scope="row">{module.Espressif || 0}</th>
                  <th scope="row">{module.Gender || 'None'}</th>
                  <th scope="row">{module.HardType ? (module.HardType == "2" ? 'Pincho' : 'Slide') : 'Pincho'}</th>
                </tr>

              ) : ("")
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ModuleList
